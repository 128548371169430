









import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonSize from '../../app_code/ButtonSizing';

@Component
export default class Button extends Vue {
  @Prop()
  private readonly disabled!: boolean;

  @Prop()
  private readonly width!: string;

  @Prop({ default: '1em' })
  private readonly padding!: string;

  @Prop({ default: false })
  private readonly shadow!: boolean;

  @Prop()
  private readonly loading!: boolean;

  @Prop()
  private readonly colour!: string;

  @Prop()
  private readonly backgroundColour!: string;

  @Prop()
  private readonly block!: boolean;

  @Prop({ default: '' })
  private readonly border!: string;

  @Prop({ default: ButtonSize.Normal })
  private readonly size!: ButtonSize;

  @Prop()
  private readonly icon!: string;

  @Prop({ default: 'white' })
  private readonly iconColour!: string;

  @Prop({ default: true })
  private readonly margin!: boolean;

  handleClicked(): void {
    this.$emit('click');
  }
}
