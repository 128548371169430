































import { BrowserStorageService, ServiceManager, ServiceType, API, Config } from 'client-website-ts-library/services';
import { Component, Vue } from 'vue-property-decorator';

import Navigation from './Navigation.vue';
import NavigationSide from './NavigationSide.vue';
import DropDownList from '../UI/DropDownList.vue';

@Component({
  components: {
    Navigation,
    NavigationSide,
    DropDownList,
  },
})
export default class Header extends Vue {
    private isStuck = false;

    private mobileNavActive = false;

    /* Configuration Options */
    private gradient = false;

    private fixed = false;

    private stickyNav = true;

    private eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

    mounted() {
      this.checkScroll();
    }

    checkScroll(): void {
      this.isStuck = window.scrollY > 0;
    }

    created() {
      window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }
}
