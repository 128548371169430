

























import { Config } from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Form from './Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class CurrentOffer extends Vue {
  private formCtx: Context | null = null;

  mounted() {
    this.formCtx = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: Config.Website.Settings!.WebsiteId,
        },
      ],
    };
  }
}
